import React from "react"
import { Link } from 'gatsby'
import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"
import Opensees from "../../../../../components/openseesrect"
import Image from "../../../../../images/AsBuilt.jpg"




const LSAnalyticalRectangular = () => {
  //javascript
  const title = 'LS Thresholds: Analytical estimation - Rectangular Piers';
  const metatitle = 'LS Thresholds: Analytical estimation - Rectangular Piers';
  const description = 'Meta Description for LS Thresholds: Analytical estimation - Rectangular Piers';
  const metadescription = description;
  return(
    <Layout bodyclass="ls-analytical" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
            <ul>
              <li key="1">
                <Link className="more" to="/bridge-specific/capacity/as-built-piers/ls-analytical/circular">Cylindrical Piers</Link>
              </li>
              <li key="1">
                <Link className="more actclass" to="/bridge-specific/capacity/as-built-piers/ls-analytical/rectangular">Rectangular Piers</Link>
              </li>
          </ul>
        </nav>
        <div className="content">
          <div className="api">
            <Opensees/>
          </div>
        </div>
        <a class="analytical-img" href={Image} target="_blank"><img src={Image} /></a>
      
    </Layout>
  )
}

export default LSAnalyticalRectangular
